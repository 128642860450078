<template>
    <RoleForm :role="role" :isEdit="false"/>
</template>

<script>

import RoleForm from "@/modules/Admin/Components/RoleForm.vue";

export default {
  name: "CreateRolePage",
  components: {RoleForm},
  props: {
      scopeType: {
          type: String,
          required: true
      }
  },
  data() {
    return {
      scopeTypes: ['application', 'resort', 'admin'],
      role: {
          name: '',
          scope_type: {
              id: 1,
              name: 'application'
          },
          enabled_features: []
      }
    }
  },
  created() {
    if (this.scopeType) {
        this.role.scope_type = {
            id: this.scopeTypes.indexOf(this.scopeType) + 1,
            name: this.scopeType
        }
    }
  }
};
</script>
